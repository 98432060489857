import React from 'react';
import { useFrame } from '@react-three/fiber';
import CurvedPlaneMaterial from './CurvedShaderMaterial';

const CurvedPlane = ({ position, rotation, getModelMatrix, ...props }) => {
    const meshRef = React.useRef();
    const material = React.useMemo(() => new CurvedPlaneMaterial(), []);
    useFrame(() => {
        if (meshRef.current && getModelMatrix) {
            const modelMatrix = meshRef.current.matrixWorld;
            getModelMatrix(modelMatrix);
        }
    });
    return (
        <mesh position={position} rotation={rotation} ref={meshRef} {...props}>
            <planeGeometry args={[1, 1, 32, 32]} />
            <primitive object={material} attach="material" transparent />
        </mesh>
    );
};

export default CurvedPlane;
