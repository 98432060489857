import axios from 'axios';

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API + '/api',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

client.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let res = error.response;
        return Promise.reject(error);
    }
);

export default client;
